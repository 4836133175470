.ui-interactive-puzzler {
  --modal-shade: var(--theme-background);
  --modal-bg: var(--theme-background);
  --modal-stroke: 1px solid var(--theme-surface-outline);
  --modal-border-radius: var(--box-radius);
  --modal-page: 1;
  --modal-close-size: 1.5rem;
  --modal-link-facebook: #1877f2;
  --modal-link-whatsapp: #25d366;
  --modal-link-x: #8e8d8d;
  --modal-link-copy-tooltip: #299934;
  --modal-color: var(--text-primary);
  /* hard code a fixed color here for puzzler ui that doesnt support dark mode */
  color: #0f0f0f;
}

.ui-interactive-puzzler noscript {
  color: var(--text-primary);
}

@supports (color: color-mix(in srgb, red, blue)) {
  .ui-interactive-puzzler {
    --modal-shade: color-mix(in srgb, var(--theme-background), transparent 10%);
  }
}

.ui-interactive-puzzler-container {
  width: min(100%, 870px);
  height: clamp(560px, 90vw, 620px);
  margin: auto;
  position: relative;
}

/* MODAL */
.ui-interactive-puzzler-modal {
  background: var(--modal-shade);
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  text-align: center;
  transition: 0.33s opacity;
  visibility: hidden;
  opacity: 0;
  color: var(--modal-color);
  fill: currentColor;
}

.ui-interactive-puzzler-modal[data-show="true"] {
  visibility: visible;
  opacity: 1;
}

.ui-interactive-puzzler-modal svg {
  fill: inherit;
}

.ui-interactive-puzzler-modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: var(--modal-close-size);
  height: var(--modal-close-size);
  z-index: 1;
  background: 0;
  border: 0;
  color: var(--modal-color);
  fill: currentColor;
}

.ui-interactive-puzzler-modal-body {
  border: var(--modal-stroke);
  background: var(--modal-bg);
  border-radius: var(--modal-border-radius);
  width: clamp(200px, 90%, 320px);
  min-height: 100px;
  position: relative;
  display: grid;
  grid-template-columns: 100% 100%;
  overflow: hidden;
  outline: 0 !important;
}

.ui-interactive-puzzler-modal-page {
  display: grid;
  place-items: center;
  transition: transform 0.25s;
  transform: translateX(calc(-100% * (var(--modal-page) - 1)));
}

.ui-interactive-puzzler-share {
  color: var(--modal-color);
  fill: currentColor;
  border: 1px solid var(--theme-surface-outline);
  font-weight: bold;
  background: none;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  height: 3rem;
  align-items: center;
  padding: 0.5rem;
  border-radius: var(--modal-border-radius);
  position: relative;
  --tooltip-opacity: 0;
  --tooltip-visibility: hidden;
}

.ui-interactive-puzzler-share[data-type="facebook"] { fill: var(--modal-link-facebook) }
.ui-interactive-puzzler-share[data-type="x"] { fill: var(--modal-link-x) }
.ui-interactive-puzzler-share[data-type="whatsapp"] { fill: var(--modal-link-whatsapp) }

.ui-interactive-puzzler-share:hover {
  text-decoration: none;
  background: var(--theme-surface-tile);
}

.ui-interactive-puzzler-share svg {
  width: 2rem;
  height: 2rem;
  display: block;
}

.ui-interactive-puzzler-share-copy-link {
  background: var(--modal-link-copy-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  font-size: 13px;
  transform: translate(-50%, -4px);
  padding: 0.2em 0.5em;
  color: white;
  border-radius: 3px;
  pointer-events: none;
  transition: 0.2s opacity;
  opacity: var(--tooltip-opacity);
  visibility: var(--tooltip-visibility);
}

.ui-interactive-puzzler-share-copy-link:after {
  background: inherit;
  content: "";
  height: 8px;
  position: absolute;
  right: calc(50% - 4px);
  top: calc(100% - 4px);
  transform: rotate(45deg);
  width: 8px;
}

.ui-interactive-puzzler-share[data-copied="true"] {
  --tooltip-opacity: 1;
  --tooltip-visibility: visible;
}

/* PUZZLER FIXES */
.ui-interactive-puzzler .pml-sdk .popup-overlay[class] {
  background: var(--modal-shade);
}

.ui-interactive-puzzler .pml-sdk .hint-popup[class],
.ui-interactive-puzzler .popup-background[class] {
  border: var(--modal-stroke);
}

.ui-interactive-puzzler .sw-timer[class] {
  white-space: nowrap;
}

.ui-interactive-puzzler button {
  cursor: pointer !important;
}

.ui-interactive-puzzler .popup-close {
  height: 32px;
  width: 32px;
}

.ui-interactive-puzzler .scribble-pad-container {
  background: white;
  padding: 5px;
}